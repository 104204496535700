.results {
    flex-grow: 1;
    margin-top: 1rem;
    padding: 0 2rem;
    padding-bottom: 0.5rem;

    &>div {
        border: none !important;
        height: 100%;
        margin-left: auto;
        width: 0;
        overflow: hidden;
        transition: width .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
}


.has-results {
    .results {
        &>div {
            width: 100%
        }
    }
}

.data-entry {
    margin-bottom: 0.5rem;
    max-width: 20rem;

    &>div {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

}

html,
body,
#root {
    height: 100%;
}

.content {
    flex-grow: 1;
}

.header {
    .backdrop {
        z-index: -10;
        height: 10rem;
    }
}