$primary: #025aa0;
$secondary: #e8e8e8;
$danger: #f24236;
$success: #5e8038;
$dark: #13293d;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "dark": $dark,
  "danger": $danger,
);